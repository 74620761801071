export default {
  methods: {
    getProcesses(params) {
      return this.$api.get('processes', params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    createProcess(params) {
      return this.$api.post('process/0', params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    getProcess(id) {
      return this.$api.get('process/' + id)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    updateProcess(id, params) {
      return this.$api.post('process/' + id, params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    deleteProcess(id) {
      return this.$api.delete('process/' + id)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    }
  }
}
