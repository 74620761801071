<template>
  <v-menu
    ref="menu"
    v-model="isShowMenu"
    transition="slide-y-transition"
    offset-y
    max-width="290px"
    min-width="auto"
    class="month-input"
  >
    <template v-slot:activator="{ on, attrs }">
      <rcc-text-input
        :value="dateFormatted"
        label="Период бронирования"
        append-icon="mdi-calendar"
        readonly
        width="180px"
        :hide-details="hideInputDetails"
        v-bind="attrs"
        v-on="on"
        @click:append="handleInputClick"
      />
    </template>

    <rcc-month-picker
      :value="value"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </v-menu>
</template>

<script>
import RccTextInput from './rcc-text-input'
import RccMonthPicker from 'Components/controls/rcc-month-picker'

export default {
  name: 'RccMothInput',

  components: {
    RccTextInput,
    RccMonthPicker
  },

  inheritAttrs: false,

  props: {
    value: {
      type: String,
      default: ''
    },

    hideInputDetails: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isShowMenu: false
    }
  },

  computed: {
    dateFormatted() {
      return (this.value && this.value.split('-').reverse().join('.')) || ''
    }
  },

  methods: {
    handleInputClick() {
      this.isShowMenu = true
    }
  }
}
</script>

<style lang="scss">
.month-input {
  cursor: pointer;
}
</style>
