<template>
  <v-form ref="form" @submit.prevent="handleFormSubmit" class="rcc-form">
    <div class="rcc-form__form-body">
      <rcc-text-input
        v-model="form.name"
        label="Название процесса"
        width="400px"
        :rules="[value => isRequiredField({ value, field: 'Название процесса' })]"
      />

      <rcc-checkbox
        v-model="processType"
        label="Активировать консолидационное бронирование"
        name="process_type"
      />

      <section class="rcc-form__section">
        <h2 class="rcc-form__section-title">
          Регионы
        </h2>

        <div v-if="regionsOptions.length === 0">Нет доступных регионов</div>

        <rcc-checkboxes-group
          v-else
          v-model="form.region_ids"
          :options="regionsOptions"
          class="rcc-form__regions-grid"
        />
      </section>

      <section class="rcc-form__section">
        <h2 class="rcc-form__section-title">
          Поставщики
          <span class="rcc-form__title-explanation">
            Показаны только поставщики в статусе "Активен"
          </span>
        </h2>

        <div v-if="suppliersOptions.length === 0">Нет доступных поставщиков</div>

        <div v-else>
          <rcc-button @click="selectAllSuppliers">Выбрать все</rcc-button>
          <rcc-button class="ml-5" @click="unselectAllSuppliers" isOutlined >Отменить все</rcc-button>

          <rcc-checkboxes-group
            v-model="form.supplier_ids"
            :options="suppliersOptions"
            class="rcc-form__suppliers-grid"
          />
        </div>
      </section>

      <section class=booking-period>
        <h2 class="rcc-form__section-title">
          Дни бронирования
        </h2>

        <rcc-select-booking-period v-model="form.booking_date" />
      </section>

      <section class="rcc-form__section">
        <h2 class="rcc-form__section-title">Повтор</h2>
        <rcc-checkbox label="Повторять до окончания времени" v-model="form.is_cycled" name="is_cycled" />

        <h2 class="rcc-form__section-title">
          Расписание обхода
        </h2>

        <rcc-radio-group
          v-model="form.schedule_type"
          :options="scheduleTypeOptions"
          name="schedule_type"
        />

        <div class="rcc-form__schedule">
          <rcc-checkboxes-group
            v-show="form.schedule_type === 'WEEK'"
            v-model="week_days"
            :options="weekDaysOptions"
          />

          <rcc-checkboxes-group
            v-show="form.schedule_type === 'MONTH'"
            v-model="month_days"
            :options="monthDaysOptions"
            class="rcc-form__month-days-grid"
          />
        </div>
      </section>

      <section class="rcc-form__section">

        <!-- <rcc-number-input
          class="rcc-form__number-input"
          width="400px"
          :min="0"
          :max="100"
          v-model="form.decrease_step"
          label="Шаг снижения кол-ва паллет"
          :rules="[value => isRequiredField({ value, field: 'Шаг снижения кол-ва паллет' })]"
        /> -->

        <div class="rcc-form__round-trip-time">
          <rcc-time-input
            v-model="startRoundTime"
            class="rcc-form__time-input"
            width="190px"
            label="Время обхода с"
            :max="maxStartBypassTime"
            :rules="[
              fromBypassTimeValidation,
              value => isRequiredField({ value, field: 'Время обхода с' })
            ]"
          />

          <rcc-time-input
            v-model="endRoundTime"
            class="rcc-form__time-input"
            width="190px"
            label="Время обхода по"
            :min="minStartBypassTime"
            :rules="[
              toBypassTimeValidation,
              value => isRequiredField({ value, field: 'Время обхода по' })
            ]"
          />
        </div>

        <!-- <rcc-number-input
          class="rcc-form__number-input"
          width="400px"
          :min="1"
          :max="31"
          v-model="form.booking_period"
          label="Период бронирования в календарных днях"
          :rules="[value => isRequiredField({ value, field: 'Период бронирования' })]"
        /> -->

        <rcc-text-input
          v-model="form.vehicle_number"
          width="400px"
          label="Значение для поля Номер ТС"
          :rules="[value => isRequiredField({ value, field: 'Номер ТС' })]"
        />
      </section>
    </div>

    <rcc-confirm-dialog
      :is-show.sync="isShowConfirmDialog"
      title="Удалить процесс?"
      text="Данное действие приведет к безвозвратному удалению процесса. Вы уверены?"
      :confirmCallback="handleDeleteProcess"
    />

    <rcc-footer-buttons
      :danger-text="dangerButtonText"
      :is-submit="isSubmit"
      @danger-click="handleDeleteProcessClick"
      @cancel-click="$router.push({ path: '/', name: 'processes' })"
    />
  </v-form>
</template>

<script>
import { timeDifference } from '@/utils/time'
import Page from '@/mixins/page'
import { required } from 'vuelidate/lib/validators'
import Form from '@/mixins/form'
import ProcessApi from '@/api/process-list'
import { getActiveSuppliersList } from '@/api/suppliers'
import { getRegionsList } from '@/api/regions'
import { getOptions } from '@/utils/get-options'

import RccTextInput from 'Components/controls/rcc-text-input'
import RccFooterButtons from 'Components/layouts/rcc-footer-buttons'
import RccRadioGroup from 'Components/controls/radio-group/rcc-radio-group.vue'
import RccCheckboxesGroup from 'Components/controls/rcc-checkboxes-group.vue'
import RccCheckbox from 'Components/controls/rcc-checkbox.vue'
import RccButton from 'Components/controls/rcc-button'
import RccTimeInput from 'Components/controls/rcc-time-input.vue'
import RccConfirmDialog from 'Components/dialogs/rcc-confirm-dialog'
import RccSelectBookingPeriod from 'Components/select-booking-period'

const BYPASS_INTERVAL = 15

const PROCESS_TYPE_RADIO_OPTIONS = [
  { value: 'intermediate', label: 'Промежуточное' },
  { value: 'consolidation', label: 'Консолидационное', isDisabled: true }
]

const WEEK_DAYS_OPTIONS = [
  { value: 1, label: 'Пн' },
  { value: 2, label: 'Вт' },
  { value: 3, label: 'Ср' },
  { value: 4, label: 'Чт' },
  { value: 5, label: 'Пт' },
  { value: 6, label: 'Сб', color: 'red-inactive' },
  { value: 7, label: 'Вс', color: 'red-inactive' }
]

const BYPASS_SCHEDULE_OPTIONS = [
  { value: 'WEEK', label: 'По дням недели' },
  { value: 'MONTH', label: 'По числам месяца' }
]

const DEFAULT_BOOKING_DATE = {
  date: new Date().toISOString().substr(0, 7),
  days: []
}

export default {
  components: {
    RccTextInput,
    RccFooterButtons,
    RccRadioGroup,
    RccCheckboxesGroup,
    RccCheckbox,
    RccButton,
    RccTimeInput,
    RccConfirmDialog,
    RccSelectBookingPeriod
  },

  mixins: [Page, Form, ProcessApi],

  data() {
    return {
      dangerButtonText: '',
      week_days: [],
      month_days: [],
      startRoundTime: null,
      endRoundTime: null,
      regionsOptions: [],
      suppliersOptions: [],
      isShowConfirmDialog: false,

      form: {
        type: 'intermediate',
        region_ids: [],
        supplier_ids: [],
        schedule_type: 'WEEK',
        schedule_days: [],
        decrease_step: 50,
        round_time: [],
        booking_period: 360,
        vehicle_number: null,
        is_cycled: false,
        booking_date: DEFAULT_BOOKING_DATE
      }
    }
  },

  validations: {
    form: {
      name: { required },
      schedule_days: { required },
      // decrease_step: { required },
      round_time: { required },
      // booking_period: { required },
      vehicle_number: { required }
    }
  },

  computed: {
    processTypeRadioOptions() {
      return PROCESS_TYPE_RADIO_OPTIONS
    },

    scheduleTypeOptions() {
      return BYPASS_SCHEDULE_OPTIONS
    },

    weekDaysOptions() {
      return WEEK_DAYS_OPTIONS
    },

    monthDaysOptions() {
      const days = []

      for (let i = 1; i <= 31; i++) {
        days.push({ value: i, label: i + '' })
      }

      return days
    },

    maxStartBypassTime() {
      if (!this.endRoundTime) {
        return ''
      }

      const [hour, minute] = this.endRoundTime.split(':')
      return this.$moment({ hour, minute }).subtract(BYPASS_INTERVAL, 'minutes').format('HH:mm')
    },

    minStartBypassTime() {
      if (!this.startRoundTime) {
        return ''
      }

      const [hour, minute] = this.startRoundTime.split(':')
      return this.$moment({ hour, minute }).add(BYPASS_INTERVAL, 'minutes').format('HH:mm')
    },

    processType: {
      get() {
        return this.form.type === 'consolidation'
      },

      set(value) {
        this.form.type = value ? 'consolidation' : 'intermediate'
      }
    }
  },

  created() {
    this.getCheckboxesData()
  },

  methods: {
    timeDifference,

    getCheckboxesData() {
      getOptions.bind(this)([{
        method: getRegionsList,
        target: 'regionsOptions',
        errorMessage: 'Не удалось загрузить данные для Регионов',
        keyName: 'value',
        valueName: 'label'
      }])

      getOptions.bind(this)([{
        method: getActiveSuppliersList,
        target: 'suppliersOptions',
        errorMessage: 'Не удалось загрузить данные для Поставщиков',
        keyName: 'value',
        valueName: 'label'
      }])
    },

    selectAllSuppliers() {
      this.form.supplier_ids = this.suppliersOptions.map(option => {
        return option.value
      })
    },

    unselectAllSuppliers() {
      this.form.supplier_ids = []
    },

    fromBypassTimeValidation(value) {
      return !this.endRoundTime ||
        (
          this.timeDifference(this.endRoundTime, value, 'minutes') >= BYPASS_INTERVAL ||
            'Не может быть больше ' + this.maxStartBypassTime
        )
    },

    toBypassTimeValidation(value) {
      return !this.startRoundTime ||
        (
          this.timeDifference(value, this.startRoundTime, 'minutes') >= BYPASS_INTERVAL ||
            'Не может быть меньше ' + this.minStartBypassTime
        )
    },

    beforeSubmit() {
      this.form.round_time = [this.startRoundTime, this.endRoundTime]

      if (this.form.schedule_type === 'WEEK') {
        this.form.schedule_days = this.week_days
      } else {
        this.form.schedule_days = this.month_days
      }

      if(!this.form.region_ids || this.form.region_ids.length === 0 ) {
        window.snackbar('Выберите хотя бы один регион', 'error')
        return false
      }

      if(!this.form.supplier_ids || this.form.supplier_ids.length === 0 ) {
        window.snackbar('Выберите хотя бы одного поставщика', 'error')
        return false
      }

      if(!this.form.schedule_days || this.form.schedule_days.length === 0 ) {
        window.snackbar('В расписании должен быть выбран хотя бы один день', 'error')
        return false
      }

      return true
    },

    getFilteredFormData() {
      const data = { ...this.form }

      if (data.booking_date?.days.length === 0) {
        delete data.booking_date
      }

      return data
    },

    handleDeleteProcessClick() {
      this.isShowConfirmDialog = true
    },

    handleDeleteProcess() {
      return
    }
  }
}
</script>

<style lang="scss" scoped>
.rcc-form {
  &__title-explanation {
    font-size: 15px;
    font-style: italic;
  }

  &__schedule {
    margin-top: 20px;
  }

  &__round-trip-time {
    display: flex;
    gap: 20px;
  }

  &__time-input {
    flex: none
  }

  .booking-period {
    width: 100%;
    max-width: 1100px;
    border-radius: $base-border-radius;
    padding: 20px;
    border: 1px solid $base-text-color;
  }
}
</style>

<style lang="scss">
.rcc-form {
  &__month-days-grid {
    flex-direction: column;
    align-content: flex-start;
    max-height: 110px;

    & > div {
      width: 10%;
    }
  }

  &__regions-grid {
    max-width: 70%;

    & > div {
      width: 25%;
    }

    @media screen and (max-width: 1000px) {
      max-width: 100%;
    }

    @media screen and (max-width: 750px) {
      & > div {
        width: 33%;
      }
    }

    @media screen and (max-width: 650px) {
      & > div {
        width: 50%;
      }
    }
  }

  &__suppliers-grid {
    margin-top: 20px;
    width: 90%;

    & > div {
      width: 33%;
    }

    @media screen and (max-width: 1200px) {
      width: 100%;

      & > div {
        width: 50%;
      }
    }

    @media screen and (max-width: 650px) {
      & > div {
        width: 100%;
      }
    }
  }
}
</style>
