<template>
  <div class="select-booking-period">
    <rcc-month-input
      v-model="date"
      hide-input-details
    />

    <rcc-checkboxes-group
      v-model="days"
      :options="monthDaysOptions"
      class="days"
    />
  </div>
</template>

<script>
import RccMonthInput from 'Components/controls/rcc-month-input'
import RccCheckboxesGroup from 'Components/controls/rcc-checkboxes-group'

export default {
  name: 'RccSelectBookingPeriod',

  components: {
    RccMonthInput,
    RccCheckboxesGroup
  },

  props: {
    value: {
      type: Object,
      default: () => ({
        date: new Date().toISOString().substr(0, 7),
        days: []
      })
    }
  },

  watch: {
    daysInMonth(count) {
      this.days = this.days.filter(day => day <= count)
    }
  },

  computed: {
    daysInMonth() {
      if (!this.date) {
        return 0
      }

      return this.$moment(this.date, 'YYYY-MM').daysInMonth()
    },

    monthDaysOptions() {
      const days = []

      for (let i = 1; i <= this.daysInMonth; i++) {
        days.push({ value: i, label: i + '' })
      }

      return days
    },

    date: {
      get() {
        return this.value.date
      },

      set(value) {
        this.updateBookingDate({ date: value })
      }
    },

    days: {
      get() {
        return this.value.days
      },

      set(value) {
        this.updateBookingDate({ days: value })
      }
    }
  },

  methods: {
    updateBookingDate(data) {
      this.$emit('input', { ...this.value, ...data })
    }
  }
}
</script>

.<style lang="scss" scoped>
.select-booking-period {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  .days {
    flex-direction: column;
    align-content: space-between;
    max-height: 110px;

    & > div {
      width: 10%;
    }
  }
}
</style>
