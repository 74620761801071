<script>
import ProcessForm from './ProcessForm'

export default {
  extends: ProcessForm,

  methods: {
    handleFormSubmit() {
      const result = this.beforeSubmit()
      if (!result) {
        return
      }

      if (this.$v.$invalid) {
        this.$refs.form.validate()
        window.snackbar('Ошибки в форме', 'error')
        return
      }

      this.isSubmit = true
      this.createProcess(this.getFilteredFormData())
        .then(() => {
          window.snackbar('Процесс успешно добавлен', 'info')
          this.$router.push({ name: 'processes' })
        })
        .finally(() => { this.isSubmit = false })
    }
  }
}
</script>