<template>
  <v-date-picker
    type="month"
    locale="ru"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'RccMothPicker',

  inheritAttrs: false
}
</script>
